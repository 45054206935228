import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { Parallax } from 'react-scroll-parallax';
const szemuvegkeretek = () => (
  <Layout>
    <SEO title="Szemüvegkeretek" description="Egyénre szabott optika Zugló szívében"  />
    <div className="sitewrap xl:max-w-2xl justify-center  mr-auto ml-auto      mt-10 ">
    <div className="  flex    mr-auto ml-auto   mt-20">
    <div className="F12F7B p-4      mb-10 mt-10 ">




<div className="left2 mt-20 "><h3 className="maintext2 left2 ">Női, férfi, gyerek és sport keretek is megtalálhatóak üzletünkben.</h3>
<h3 className="maintext2 mt-2 left2">
Minőségi jól szervízelhető kereteket tartunk.</h3>
<h3 className="maintext2 mt-2 left2 "> Márkáink: </h3>

<ul className="maintext2 mt-2 left2 text-left">
<li>HEAD</li>
<li>Tommy Hilfiger</li>
<li>Hugo BOSS</li>
<li>BMW</li>
<li>Liebeskind</li>
<li>More & More</li>
<li>S. Oliver</li>
<li>Reserve</li>
<li>Solideye</li>
<li>Flair</li>
<li>Vistan</li>
<li>Milo & Me</li>
<li>Leader</li>
</ul>





</div></div>

    </div></div>
  </Layout>
)

export default szemuvegkeretek
